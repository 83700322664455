<template>
	<main>
		<div class="main" :class="[full_screen ? 'full' : '']">
			<div class="header-section">
				<div class="action">
					<el-icon class="btn el-icon-refresh" title="刷新" @click.native="refresh"/>
					<el-icon class="btn el-icon-full-screen" title="全屏" @click.native="toFullScreen" v-if="!full_screen"/>
					<el-icon class="btn el-icon-aim" title="退出全屏" @click.native="exitFullScreen" v-else/>
				</div>
				<div class="title">设备巡检信息监控大屏</div>
			</div>
			<div class="chart-section">
				<div class="item">
					<div class="chart-box">
						<div class="title">
							<div class="tit">
								<div class="text">设备类型统计</div>
							</div>
						</div>
						<div class="chart" id="good-type"></div>
					</div>
					<div class="chart-box">
						<div class="title">
							<div class="tit">
								<div class="text">设备分类统计</div>
							</div>
						</div>
						<div class="chart" id="good-category"></div>
					</div>
				</div>
				<div class="item">
					<div class="chart-box">
						<div class="title">
							<div class="tit">
								<div class="text">维修员排名</div>
								<el-select class="select" v-model="user_rank.date" placeholder="时间周期">
									<el-option v-for="(item,index) in date_option" :key="index" :label="item.title" :value="item.id"></el-option>
								</el-select>
							</div>
						</div>
						<div class="chart" id="user-rank"></div>
					</div>
				</div>
				<div class="item">
					<div class="chart-box">
						<div class="title">
							<div class="tit">
								<div class="text">维修员处理工单统计</div>
								<el-select class="select" v-model="user_task.date" placeholder="时间周期">
									<el-option v-for="(item,index) in date_option" :key="index" :label="item.title" :value="item.id"></el-option>
								</el-select>
								<el-select class="select" v-model="user_task.type" placeholder="全部工单">
									<el-option v-for="(item,index) in type_option" :key="index" :label="item.title" :value="item.id"></el-option>
								</el-select>
							</div>
						</div>
						<div class="chart" id="user-task"></div>
					</div>
				</div>
				<div class="item">
					<div class="chart-box">
						<div class="title">
							<div class="tit">
								<div class="text">各部门报废设备统计</div>
								<el-select class="select" v-model="department_drop.date" placeholder="时间周期">
									<el-option v-for="(item,index) in date_option" :key="index" :label="item.title" :value="item.id"></el-option>
								</el-select>
							</div>
						</div>
						<div class="chart" id="department-drop"></div>
					</div>
				</div>
				<div class="item item-2">
					<div class="chart-box">
						<div class="title">
							<div class="tit">
								<div class="text">各部门工单统计</div>
								<el-select class="select" v-model="department_task.date" placeholder="时间周期">
									<el-option v-for="(item,index) in date_option" :key="index" :label="item.title" :value="item.id"></el-option>
								</el-select>
								<el-select class="select" v-model="department_task.type" placeholder="全部工单">
									<el-option v-for="(item,index) in type_option" :key="index" :label="item.title" :value="item.id"></el-option>
								</el-select>
							</div>
						</div>
						<div class="chart" id="department-task"></div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
import {dashboard} from "@/api/admin";
import screenfull from 'screenfull'

import * as echarts from 'echarts/core';
import {
	TitleComponent,
	TooltipComponent,
	ToolboxComponent,
	VisualMapComponent,
	GridComponent,
	LegendComponent,
} from 'echarts/components';
import {PieChart, BarChart, LineChart} from 'echarts/charts';
import {LabelLayout} from 'echarts/features';
import {CanvasRenderer} from 'echarts/renderers';

echarts.use([
	TitleComponent,
	TooltipComponent,
	ToolboxComponent,
	VisualMapComponent,
	GridComponent,
	LegendComponent,
	PieChart,
	BarChart,
	LineChart,
	CanvasRenderer,
	LabelLayout
]);

export default {
	data() {
		return {
			chart: {
				good_type: "",
				good_category: "",
				user_rank: "",
				department_drop: "",
				user_task: "",
				department_task: "",
			},
			color: ['#63b2ee', '#76da91', '#f8cb7f', '#f89588', '#7cd6cf', '#9192ab', '#7898e1', '#efa666', '#eddd86', '#9987ce', '#63b2ee', '#76da91'],
			color_dark: ['#05f8d6', '#0082fc', '#fdd845', '#22ed7c', '#09b0d3', '#1d27c9', '#f9e264', '#f47a75', '#009db2', '#024b51', '#0780cf', '#765005'],

			user_rank: {
				date: 2,
			},
			department_drop: {
				date: 2,
			},
			user_task: {
				date: 2,
				type: 0,
			},
			department_task: {
				date: 2,
				type: 0,
			},

			date_option: [{id: 1, title: "本周"}, {id: 2, title: "本月"}, {id: 3, title: "本年"}],
			type_option: [{id: 0, title: "全部"}, {id: 1, title: "维修工单"}, {id: 2, title: "保养工单"}, {id: 3, title: "巡检工单"}],
			full_screen: false,
		}
	},
	watch: {
		user_rank: {
			deep: true,
			handler() {
				this.getUserRank();
			}
		},
		department_drop: {
			deep: true,
			handler() {
				this.getDepartmentDrop();
			}
		},
		user_task: {
			deep: true,
			handler() {
				this.getUserTask();
			}
		},
		department_task: {
			deep: true,
			handler() {
				this.getDepartmentTask();
			}
		},
	},
	mounted() {
		this.refresh();
		this.listenFullScreen();
		this.listenSize();
	},
	methods: {
		listenFullScreen() {
			screenfull.on('change', () => {
				this.full_screen = screenfull.isFullscreen;
			})
		},
		listenSize() {
			window.addEventListener('resize', () => {
				setTimeout(() => {
					for (let o in this.chart) {
						this.chart[o]?.resize();
					}
				}, 100)
			})
		},
		toFullScreen() {
			if (screenfull.isEnabled) {
				screenfull.request();
			}
		},
		exitFullScreen() {
			screenfull.exit();
		},
		refresh() {
			this.getGoodType();
			this.getGoodCategory();
			this.getUserRank();
			this.getDepartmentDrop();
			this.getUserTask();
			this.getDepartmentTask();
		},
		getDepartmentTask() {
			dashboard.department_task(this.department_task).then((res) => {
				this.createDepartmentTask(res.list, res.date)
			})
		},
		createDepartmentTask(params, date) {
			var chartDom = document.getElementById('department-task');
			var myChart = echarts.init(chartDom);
			var option;

			const xAxis_data = [];
			date.map((item, index) => {
				xAxis_data.push(item.title)
			})
			const series = [];
			params.map((item, index) => {
				const data = [];
				item.date.map((v, i) => {
					data.push(v.number)
				})
				series.push({
					name: item.title,
					type: 'line',
					data: data
				})
			})
			option = {
				color: this.color,
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				grid: {
					left: '1%',
					right: '1%',
					bottom: '10%',
					containLabel: true
				},
				legend: {
					top: 'bottom'
				},
				xAxis: {
					type: 'category',
					data: xAxis_data
				},
				yAxis: {
					type: 'value',
					minInterval: 1,
				},
				series: series
			};

			option && myChart.setOption(option);
			this.chart.department_task = myChart;
		},
		getUserTask() {
			dashboard.user_task(this.user_task).then((res) => {
				this.createUserTask(res.list, res.date)
			})
		},
		createUserTask(params, date) {
			var chartDom = document.getElementById('user-task');
			var myChart = echarts.init(chartDom);
			var option;

			const xAxis_data = [];
			date.map((item, index) => {
				xAxis_data.push(item.title);
			})

			const series = [];
			params.map((item, index) => {
				const data = [];
				item.date.map((v, i) => {
					data.push(v.number)
				})
				series.push({
					name: item.username,
					type: 'line',
					data: data
				})
			})
			option = {
				color: this.color,
				tooltip: {
					trigger: 'axis'
				},
				grid: {
					left: '2%',
					right: '2%',
					bottom: '10%',
					containLabel: true
				},
				legend: {
					top: 'bottom'
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: xAxis_data
				},
				yAxis: {
					type: 'value',
					minInterval: 1,
				},
				series: series,
			};

			option && myChart.setOption(option);
			this.chart.user_task = myChart;
		},
		getDepartmentDrop() {
			dashboard.department_drop(this.department_drop).then((res) => {
				this.createDepartmentDrop(res.list, res.date)
			})
		},
		createDepartmentDrop(params, date) {
			var chartDom = document.getElementById('department-drop');
			var myChart = echarts.init(chartDom);
			var option;

			const xAxis_data = [];
			date.map((item, index) => {
				xAxis_data.push(item.title);
			})

			const series = [];
			params.map((item, index) => {
				const data = [];
				item.date.map((v, i) => {
					data.push(v.number)
				})
				series.push({
					name: item.title,
					type: 'line',
					data: data
				})
			})
			option = {
				color: this.color,
				tooltip: {
					trigger: 'axis'
				},
				grid: {
					left: '2%',
					right: '2%',
					bottom: '10%',
					containLabel: true
				},
				legend: {
					top: 'bottom'
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: xAxis_data,
				},
				yAxis: {
					type: 'value',
					minInterval: 1,
				},
				series: series,
			};

			option && myChart.setOption(option);
			this.chart.department_drop = myChart;
		},
		getUserRank() {
			dashboard.user_rank(this.user_rank).then((res) => {
				this.createUserRank(res.list)
			})
		},
		createUserRank(params) {
			var chartDom = document.getElementById('user-rank');
			var myChart = echarts.init(chartDom);
			var option;

			const yAxis_data = [];
			const type1_data = [];
			const type2_data = [];
			const type3_data = [];
			params.map((item, index) => {
				yAxis_data.push(item.username);
				type1_data.push(this.getNumber(item.stat, 1))
				type2_data.push(this.getNumber(item.stat, 2))
				type3_data.push(this.getNumber(item.stat, 3))
			})
			option = {
				color: this.color,
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				grid: {
					left: '2%',
					right: '2%',
					bottom: '10%',
					containLabel: true
				},
				legend: {
					top: 'bottom'
				},
				xAxis: {
					type: 'value'
				},
				yAxis: {
					type: 'category',
					data: yAxis_data
				},
				series: [
					{
						name: '维修工单',
						type: 'bar',
						stack: 'total',
						label: {
							show: true
						},
						emphasis: {
							focus: 'series'
						},
						data: type1_data
					},
					{
						name: '保养工单',
						type: 'bar',
						stack: 'total',
						label: {
							show: true
						},
						emphasis: {
							focus: 'series'
						},
						data: type2_data
					},
					{
						name: '巡检工单',
						type: 'bar',
						stack: 'total',
						label: {
							show: true
						},
						emphasis: {
							focus: 'series'
						},
						data: type3_data
					}
				]
			};

			option && myChart.setOption(option);
			this.chart.user_rank = myChart;
		},
		getGoodCategory() {
			dashboard.good_category().then((res) => {
				this.createGoodCategory(res.list)
			})
		},
		createGoodCategory(params) {
			var chartDom = document.getElementById('good-category');
			var myChart = echarts.init(chartDom);
			var option;

			const data = [];
			params.map((item, index) => {
				data.push({
					value: item.number, name: item.title
				})
			})

			option = {
				color: this.color,
				tooltip: {
					trigger: 'item',
					axisPointer: {
						type: 'shadow'
					}
				},
				grid: {
					left: '2%',
					right: '2%',
					bottom: '10%',
					containLabel: true
				},
				legend: {
					type: "scroll",
					top: 'bottom'
				},
				series: [
					{
						name: '设备分类',
						type: 'pie',
						//roseType: 'area',
						avoidLabelOverlap: true,
						radius: '50%',
						data: data,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			};

			option && myChart.setOption(option);
			this.chart.good_category = myChart;
		},
		getGoodType() {
			dashboard.good_type().then((res) => {
				this.createGoodType(res.list)
			})
		},
		createGoodType(params) {
			var chartDom = document.getElementById('good-type');
			var myChart = echarts.init(chartDom);
			var option;

			const data = [
				{value: this.getNumber(params, 1), name: '固定资产'},
				{value: this.getNumber(params, 2), name: '非固定资产'},
			];

			option = {
				color: this.color,
				tooltip: {
					trigger: 'item',
					axisPointer: {
						type: 'shadow'
					}
				},
				grid: {
					left: '2%',
					right: '2%',
					bottom: '10%',
					containLabel: true
				},
				legend: {
					top: 'bottom'
				},
				series: [
					{
						name: '设备类型',
						type: 'pie',
						//roseType: 'area',
						radius: '50%',
						data: data,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			};

			option && myChart.setOption(option);
			this.chart.good_type = myChart;
		},
		getNumber(params, type) {
			const res = params.find((item, index) => {
				return item.type === type;
			})
			return res?.number || 0;
		}
	}
}
</script>
<style lang="scss" scoped>
.main {background: #fff;}
.main.full {position: fixed;top: 0;left: 0;width: 100%;height: 100%;overflow: hidden;overflow-y: auto;z-index: $z-header;}
.header-section {height: 60px;background: url("~@/assets/admin/image/chart-title.png") no-repeat top center;background-size: contain;text-align: center;line-height: 47px;font-size: 30px;font-weight: bold;color: #fff;position: relative;
	.action {position: absolute;left: 15px;top: 15px;display: flex;align-items: center;
		.btn {color: #666;font-size: 20px;margin-right: 10px;cursor: pointer;}
	}
}
.chart-section {display: flex;flex-wrap: wrap;
	.item {width: 50%;display: flex;
		.chart-box {flex: 1;margin: 7px;padding: 7px;background: radial-gradient(rgba(231, 238, 255, 1), rgba(212, 247, 255, 1));
			.title {display: flex;align-items: center;justify-content: space-between;
				.tit {display: flex;align-items: center;flex: 1;margin-right: 15px;
					.text {font-weight: bold;font-size: 18px;}
					.select {margin-left: 7px;width: 100px;}
				}
			}
			.chart {height: 280px;}
		}
	}
	.item.item-2 {width: 100%;}
}
</style>
